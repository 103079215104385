import Command from './Command';

export default class CommandsSelector {
  public static selectCommand(state: ReduxState): Command {
    return state.command.model.data;
  }

  public static selectCommands(state: ReduxState): Command[] {
    return state.command.list.data;
  }

  public static selectCommandIsLoading(state: ReduxState): boolean {
    return state.command.model.isLoading;
  }

  public static selectCommandsIsLoading(state: ReduxState): boolean {
    return state.command.list.isFetchLoading;
  }
}
