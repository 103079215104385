import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import DevicesEffect from '../store/device/DevicesEffect';
import DevicesSelector from '../store/device/DevicesSelector';

export const DeviceHeartbeat = (): JSX.Element => {
  const device = useSelector(DevicesSelector.selectDevice);
  const [interval, setLocalInterval] = useState({} as NodeJS.Timeout);
  const [isActive, setIsActive] = useState(false);

  const startHeartbeat = useCallback(() => {
    DevicesEffect.update(device.id, {
      heartbeat: new Date(),
    });
    setLocalInterval(
      setInterval(() => {
        DevicesEffect.update(device.id, {
          heartbeat: new Date(),
        });
      }, 10000)
    );
    setIsActive(true);
  }, [setLocalInterval, device]);

  const stopHeartbeat = useCallback((): void => {
    clearInterval(interval);
    setIsActive(false);
  }, [setIsActive, interval]);

  return (
    <div>
      <span>DeviceHeartbeat</span>
      {isActive ? (
        <button type="button" onClick={stopHeartbeat}>
          Stop
        </button>
      ) : (
        <button type="button" onClick={startHeartbeat}>
          Start
        </button>
      )}
    </div>
  );
};
