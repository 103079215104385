import React, { FC, useMemo } from 'react';

type MediaKeys = keyof MediaTrackSupportedConstraints;
type MediaValues = {
  [key in MediaKeys]?: string;
};

type Props = {
  // eslint-disable-next-line react/require-default-props
  currentAudioConstraints?: MediaTrackConstraints;
  // eslint-disable-next-line react/require-default-props
  currentAudioSettings?: MediaTrackSettings;
  // eslint-disable-next-line react/require-default-props
  currentAudioCapabilities?: MediaTrackCapabilities;
};

const getDefaultAudioProcessingValues = (): MediaValues => {
  const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
  const constraintsToCheck: MediaKeys[] = [
    'noiseSuppression',
    'autoGainControl',
    'echoCancellation',
  ];

  const defaultValues: MediaValues = {};

  constraintsToCheck.forEach((constraint) => {
    if (constraint in supportedConstraints) {
      defaultValues[
        constraint
      ] = `Default ${constraint} value is ${supportedConstraints[constraint]}`;
    } else {
      defaultValues[constraint] = `${constraint} is not supported`;
    }
  });

  return defaultValues;
};

export const StreamDetails: FC<Props> = ({
  currentAudioConstraints,
  currentAudioCapabilities,
  currentAudioSettings,
}) => {
  const details = useMemo(() => {
    return getDefaultAudioProcessingValues();
  }, []);

  return (
    <div className="muted-text" style={{ fontSize: '11px' }}>
      <p>Default</p>
      <pre>{JSON.stringify(details, null, 2)}</pre>
      <p>Current constraints</p>
      <pre>{JSON.stringify(currentAudioConstraints, null, 2)}</pre>
      <p>Current settings</p>
      <pre>{JSON.stringify(currentAudioSettings, null, 2)}</pre>
      <p>Current capabilities</p>
      <pre>{JSON.stringify(currentAudioCapabilities, null, 2)}</pre>
    </div>
  );
};
