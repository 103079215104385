import Device from './Device';

export default class DevicesSelector {
  public static selectDevice(state: ReduxState): Device {
    return state.device.model.data;
  }

  public static selectDevices(state: ReduxState): Device[] {
    return state.device.list.data;
  }

  public static selectDeviceIsLoading(state: ReduxState): boolean {
    return state.device.model.isLoading;
  }

  public static selectDevicesIsLoading(state: ReduxState): boolean {
    return state.device.list.isFetchLoading;
  }
}
