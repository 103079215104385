import React from 'react';
import { useSelector } from 'react-redux';
import LogsSelector from '../store/log/LogsSelector';

export const LogMessages = (): JSX.Element => {
  const messages = useSelector(LogsSelector.selectMessages);
  return (
    <ul>
      {messages.map((m) => (
        <li key={m}>{m}</li>
      ))}
    </ul>
  );
};
