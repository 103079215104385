import { actionCreator } from 'redux-rest-helper-for-loopback';
import CommandsEffect from './CommandsEffect';

export default class CommandsAction {
  static ENTRY = 'COMMAND';

  static actionCreator = actionCreator(
    CommandsAction.ENTRY,
    CommandsEffect.restRequests
  );

  static create = CommandsAction.actionCreator.getCreateAction();

  static update = CommandsAction.actionCreator.getUpdateAction();

  static fetch = CommandsAction.actionCreator.getFetchAction();

  static delete = CommandsAction.actionCreator.getDeleteAction();

  static list = CommandsAction.actionCreator.getListAndCountAction();

  static clean = CommandsAction.actionCreator.getCleanAction();
}
