import { reducerCreator } from 'redux-rest-helper-for-loopback';
import Command from './Command';
import CommandsAction from './CommandsAction';

export default class CommandsReducer {
  private static initValues: Command = {
    id: '',
    deviceId: '',
    memberId: '',
    type: '',
    isDone: false,
  };

  public static reducer = reducerCreator(
    CommandsAction.ENTRY,
    CommandsReducer.initValues
  ).getReducer();
}
