import { ActionWPayload } from '../ActionInterfaces';

export enum LogsActionTypes {
  ADD_LOG = '@LOG:ADD_LOG',
}

type ExampleReturn = ActionWPayload<
  LogsActionTypes.ADD_LOG,
  { message: string }
>;

export type LogsActionObject = ExampleReturn;

export default class LogsAction {
  public static addLog(message: string): LogsActionObject {
    return {
      type: LogsActionTypes.ADD_LOG,
      payload: {
        message,
      },
    };
  }
}
