import { actionCreator } from 'redux-rest-helper-for-loopback';
import DevicesEffect from './DevicesEffect';

export default class DevicesAction {
  static ENTRY = 'DEVICE';

  static actionCreator = actionCreator(
    DevicesAction.ENTRY,
    DevicesEffect.restRequests
  );

  static create = DevicesAction.actionCreator.getCreateAction();

  static update = DevicesAction.actionCreator.getUpdateAction();

  static fetch = DevicesAction.actionCreator.getFetchAction();

  static delete = DevicesAction.actionCreator.getDeleteAction();

  static list = DevicesAction.actionCreator.getListAndCountAction();

  static clean = DevicesAction.actionCreator.getCleanAction();
}
