import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import CommandsEffect from '../store/command/CommandsEffect';
import DevicesSelector from '../store/device/DevicesSelector';

export const DeviceRemoveCommands = (): JSX.Element => {
  const device = useSelector(DevicesSelector.selectDevice);
  const removeAllUndoneCommands = useCallback(() => {
    const where = {
      deviceId: device.id,
      isDone: false,
    };

    CommandsEffect.find({ where })
      .then((commands) => {
        const deleteRequests = commands.map((command) =>
          CommandsEffect.delete(command.id)
        );
        return Promise.all(deleteRequests);
      })
      .then((x) => {
        // eslint-disable-next-line no-console
        console.log('All removed', x);
      });
  }, [device]);

  return (
    <div>
      <span>DeviceHeartbeat</span>
      <button type="button" onClick={removeAllUndoneCommands}>
        removeStreamCommands
      </button>
    </div>
  );
};
