/* eslint-disable no-param-reassign */
import Axios from 'axios';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getErrorMessage } from 'releox-react';
import SimplePeer, { SignalData } from 'simple-peer';
import { DeviceHeartbeat } from './components/DeviceHeartbeat';
import { DeviceRemoveCommands } from './components/DeviceRemoveCommands';
import { LogMessages } from './components/LogMessages';
import CommandsEffect from './store/command/CommandsEffect';
import Device from './store/device/Device';
import DevicesAction from './store/device/DevicesAction';
import DevicesEffect from './store/device/DevicesEffect';
import DevicesSelector from './store/device/DevicesSelector';
import LogsAction from './store/log/LogsAction';
import StreamAction from './store/stream/StreamAction';

interface Peer {
  isAnswered: boolean;
  peer: SimplePeer.Instance;
}

const peerClient: Peer = {
  isAnswered: false,
  peer: {} as SimplePeer.Instance,
};

let mediaStream: MediaStream;

type PeerConnectionType = 'init' | 'notInit';

export const DeviceScene = (): JSX.Element => {
  const localVideoRef = useRef<HTMLVideoElement>(null);
  const remoteVideoRef = useRef<HTMLVideoElement>(null);
  const dispatch = useDispatch();
  const params = useParams<{ room: string }>();
  const device = useSelector(DevicesSelector.selectDevice);

  const waitCommand = useCallback(
    (devices: Device[]) => {
      if (!devices.length) {
        return dispatch(LogsAction.addLog('Missing device'));
      }
      return dispatch(DevicesAction.fetch(devices[0].id));
    },
    [dispatch]
  );

  /*
   * Fetch device data
   */
  useEffect(() => {
    Axios.defaults.headers['Device-Authorization'] = params.room;
    DevicesEffect.find({ where: { hardwareIdentifier: params.room } })
      .then(waitCommand)
      .catch((e) => dispatch(LogsAction.addLog(getErrorMessage(e))));
  }, [params, waitCommand, dispatch]);

  const onPeerStream = useCallback(
    (remoteStream: MediaStream): void => {
      dispatch(StreamAction.playVideo(remoteVideoRef, remoteStream));
    },
    [dispatch, remoteVideoRef]
  );

  const initPeerConnection = useCallback(
    (type: PeerConnectionType, stream: MediaStream): SimplePeer.Instance => {
      const peer = new SimplePeer({
        initiator: type === 'init',
        stream,
        trickle: false,
      });
      peer.on('stream', onPeerStream);
      return peer;
    },
    [onPeerStream]
  );

  const createPeerConnection = useCallback(
    (stream: MediaStream) => {
      const peer = initPeerConnection('init', stream);
      peer.on('signal', (signalInformation: SignalData) => {
        dispatch(LogsAction.addLog('LL: peer on signal'));
        window.socket.emit('Offer', signalInformation);
      });
      peerClient.peer = peer;
    },
    [initPeerConnection, dispatch]
  );

  const openVideoStream = useCallback((): void => {
    dispatch(LogsAction.addLog('LL: CreatePeer received'));
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        dispatch(LogsAction.addLog('LL: Stream is ready'));
        mediaStream = stream;
        createPeerConnection(stream);
        // startVideoPreview(localVideoRef, stream);
        dispatch(StreamAction.playVideo(localVideoRef, stream));
      })
      .catch((e) => dispatch(LogsAction.addLog(getErrorMessage(e))));
  }, [dispatch, createPeerConnection, localVideoRef]);

  const onServerOffer = useCallback(
    (offer: SignalData) => {
      dispatch(LogsAction.addLog('LL: ServerOffer received'));
      const peer = initPeerConnection('notInit', mediaStream);
      peer.on('signal', (signalInformation: string) => {
        window.socket.emit('Answer', signalInformation);
      });
      peer.signal(offer);
      peerClient.peer = peer;
    },
    [initPeerConnection, dispatch]
  );

  /*
   * Setup socket events
   */
  const setupWebsocket = useCallback(
    (commandId: string) => {
      window.socket.on('connect', () => {
        dispatch(LogsAction.addLog('Connected'));
      });

      window.socket.on('disconnect', () => {
        dispatch(LogsAction.addLog('Disconnected'));
      });

      window.socket.on('Log', (message: string) => {
        dispatch(LogsAction.addLog(message));
      });

      window.socket.on('CreatePeer', openVideoStream);
      window.socket.on('ServerOffer', onServerOffer);
      window.socket.on('ServerAnswer', (answer: SignalData) => {
        dispatch(LogsAction.addLog('LL: ServerAnswer received'));
        peerClient.isAnswered = true;
        peerClient.peer.signal(answer);
      });
      setTimeout(() => {
        window.socket.emit('JoinRoom', commandId);
      }, 2000);
    },
    [dispatch, openVideoStream, onServerOffer]
  );

  useEffect(() => {
    if (device.id) {
      const interval = setInterval(() => {
        const where = { deviceId: device.id, type: 'STREAM', isDone: false };
        CommandsEffect.find({ where })
          .then((commands) => {
            if (commands.length) {
              clearInterval(interval);
              setupWebsocket(commands[0].id);
              CommandsEffect.update(commands[0].id, { isDone: true });
            }
          })
          .catch((e) => dispatch(LogsAction.addLog(getErrorMessage(e))));
      }, 5000);
    }
  }, [dispatch, device.id, setupWebsocket]);

  if (!device.id) return <p>Loading</p>;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h1>{`Device - ${window.socket.id}`}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <DeviceHeartbeat />
          <DeviceRemoveCommands />
          <LogMessages />
        </div>
        <div className="col-6">
          <video ref={localVideoRef} autoPlay muted />
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video ref={remoteVideoRef} autoPlay />
        </div>
      </div>
    </div>
  );
};
