import { reducerCreator } from 'redux-rest-helper-for-loopback';
import Device from './Device';
import DevicesAction from './DevicesAction';

export default class DevicesReducer {
  private static initValues: Device = {
    id: '',
    name: '',
    heartbeat: new Date(),
  };

  public static reducer = reducerCreator(
    DevicesAction.ENTRY,
    DevicesReducer.initValues
  ).getReducer();
}
