import { requestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import Command from './Command';

export default class CommandsEffect {
  public static restRequests = requestCreator<Command>(apis.COMMAND);

  public static findById = CommandsEffect.restRequests.getById;

  public static find = CommandsEffect.restRequests.getAll;

  public static create = CommandsEffect.restRequests.create;

  public static update = CommandsEffect.restRequests.update;

  public static delete = CommandsEffect.restRequests.delete;

  public static count = CommandsEffect.restRequests.count;
}
