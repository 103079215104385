import React from 'react';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from 'history';
import i18next from 'i18next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import {
  getAuthRoutes,
  releoxTranslations,
  routeMapper,
  setReleoxOptions,
} from 'releox-react';
import 'releox-react/lib/releox.css';
import io from 'socket.io-client';
import { ClientScene } from './Client';
import { DeviceScene } from './Device';
import './index.css';
import * as serviceWorker from './serviceWorker';
import rootReducer, { ReducerReturn } from './store/rootReducer';

const history = createBrowserHistory();

declare global {
  interface Window {
    socket: SocketIOClient.Socket;
  }

  type ReduxState = ReducerReturn;
}

setReleoxOptions({
  mobileSupportOnly: true,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);

i18next.use(initReactI18next).init({
  lng: 'fi',
  debug: false,
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources: releoxTranslations,
});

if (process.env.REACT_APP_WEBSOCKET_SERVER) {
  window.socket = io(process.env.REACT_APP_WEBSOCKET_SERVER);
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        {getAuthRoutes().map(routeMapper)}

        <Route path="/client/:room" component={ClientScene} />
        <Route path="/device/:room" component={DeviceScene} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();
