import { LogsActionObject, LogsActionTypes } from './LogsAction';

export default class LogsReducer {
  private static initState = {
    messages: [] as string[],
  };

  public static reducer(
    state = LogsReducer.initState,
    action: LogsActionObject
  ): typeof state {
    switch (action.type) {
      case LogsActionTypes.ADD_LOG:
        return {
          ...state,
          messages: [...state.messages, action.payload.message],
        };

      default:
        return state;
    }
  }
}
