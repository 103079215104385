/* eslint-disable no-param-reassign */
import isAppleDevice from '../../utils/is-apple-device';
import { ReduxDispatch } from '../ActionInterfaces';
import LogsAction from '../log/LogsAction';

export default class StreamAction {
  public static playVideo(
    ref: React.RefObject<HTMLVideoElement>,
    stream: MediaStream
  ) {
    return (dispatch: ReduxDispatch): void => {
      const nullMsg = 'Video ref is null';
      if (ref.current === null) {
        throw dispatch(LogsAction.addLog(nullMsg));
      }

      if (isAppleDevice()) {
        ref.current.autoplay = true;
      }

      ref.current.srcObject = stream;
      ref.current.oncanplay = (): void => {
        if (ref.current === null) {
          throw dispatch(LogsAction.addLog(nullMsg));
        }
        ref.current.play();
      };
    };
  }
}
