import { combineReducers } from 'redux';
import CommandsReducer from './command/CommandsReducer';
import DevicesReducer from './device/DevicesReducer';
import LogsReducer from './log/LogsReducer';

const rootReducer = combineReducers({
  device: DevicesReducer.reducer,
  command: CommandsReducer.reducer,
  log: LogsReducer.reducer,
});

export type ReducerReturn = ReturnType<typeof rootReducer>;

export default rootReducer;
