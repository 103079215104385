import { requestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import Device from './Device';

export default class DevicesEffect {
  public static restRequests = requestCreator<Device>(apis.DEVICE);

  public static findById = DevicesEffect.restRequests.getById;

  public static find = DevicesEffect.restRequests.getAll;

  public static create = DevicesEffect.restRequests.create;

  public static update = DevicesEffect.restRequests.update;

  public static count = DevicesEffect.restRequests.count;
}
